.userbar {
    background-color: #00519C;
    height: 80px;
    width: 50%;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .userMenu-bars {
    margin-right: 2rem;
    font-size: 2rem;
    background: none;
    color: white;
  }
  
  .user-menu {
    background-color: #060b26;
    width: 250px;
    height: 37vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: -100%;
    right: 0%;
    transition: 850ms;
    z-index: 9999;
  }
  
  .user-menu.active {
    top: 0;
    right: 0;
    transition: 350ms;
  }
  
  .user-text {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
  }
  
  .user-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  .user-text-small {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
  }
  
  .user-text-small a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 12px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  .user-text a:hover {
    background-color: #1a83ff;
  }
  
  .user-menu-items {
    width: 100%;
  }
  
  .userbar-toggle {
    background-color: #060b26;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  span {
    margin-right: 16px;
  }